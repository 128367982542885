import React from 'react';
import * as Styled from './styles';
import { Colors } from '../../../../../values/colors';

const Toggle = (props) => {
  const { 
    title, 
    value, 
    onChange, 
    description,
    options,
    id,
    name,
    isEnabled,
    canEdit
  } = props;

  return (
    <Styled.Container isEnabled={isEnabled}>
      <Styled.Line>
        <Styled.ConfigTitle>{title}</Styled.ConfigTitle>

        <div style={{ width: 258 }}>
          <select
            value={value}
            onChange={(e) => {
              onChange(e)
            }}
            disabled={!canEdit}
            className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            id={id}
            name={name}
          >
            {options?.map(option => (
              <option key={option.value} value={option.value}>{option.title}</option>
            ))}
        </select> 
      </div>
      </Styled.Line>

      <Styled.Description>{description}</Styled.Description>
      <hr style={{ height: 1, color: Colors.ultraLightGray2 }} />
    </Styled.Container>
  )
}

export default Toggle;
