import React, { useEffect, useState } from 'react';
import { getPlansMeta, updatePlan } from '../../../../services/CompaniesManagement';
import { InputDinamic, SelectConfig, SelectItem, ToggleConfig, AdsComponent } from '../components';
import { arrayMove } from '@dnd-kit/sortable';
import { ErrorMessage, SuccessMessage } from '../../../../utils/alerts';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

export const usePlan = () => {
  const [planData, setPlanData] = useState([]);
  const [planMeta, setPlanMeta] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isPlanLoading, setIsPlanLoading] = useState(false);
  const { t } = useTranslation("common");
  const { businessId } = useParams();
  
  const getPlanData = async () => {
    setIsPlanLoading(true);
    const data = await getPlansMeta(businessId);
    setPlanData(data.categories);
    setPlanMeta(data.categories);
    setIsPlanLoading(false);
  };

  const onChange = (e, data) => {

    setPlanData(state => state.map(item => {
      if (item.key === e.target.name || item.key === e.target.id ) {
        if (item.type === "bool") {
          return { ...item, value: Boolean(item.value) == 1 ? 0 : 1 }
        }
        
        if (item.type === "string" && item?.options?.multiple) {
          const values = item?.value?.length ? item?.value.split(",") : [];

          if (values?.includes(String(data?.value))) {
            return { ...item, value: values?.filter(val => val !== String(data?.value)).join(",") }
          }
          
          values.push(data.value);
          
          if (values?.length === item?.options?.list?.length) return { ...item, value: "" };

          return { ...item, value: values.join(",") }
        }
        
        return { ...item, value: e.target.value }
      }

      return item;
    }));
  };

  const onDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPlanData(state => state.map(item => {
        if (item.key === "ad_ids") {
          return {
            ...item,
            value: arrayMove(item.value, active.id, over.id)
          }
        }

        return item;
      }))
    }
  };

  const onChangeAdsTitle = (ad, newTitle) => {
    setPlanData(state => state.map(item => {
      if (item.key === "ad_ids") {
        item.value[ad] = {
          ...item.value[ad],
          title: newTitle
        };
        return item;
      }

      return item;
    }))
  };

  const onDeleteAd = (ad_index) => {
    const planConfig = [...planData];
    const adIdsIndex = planData.findIndex(item => item.key === "ad_ids");
    planConfig[adIdsIndex].value.splice(ad_index, 1);
    setPlanData(planConfig);
  };

  const onUploadImage = (event) => {
    const localImageUrl = URL.createObjectURL(event.target.files[0]);
    setPlanData(state => state.map(item => {
      if (item.key === "ad_ids") {
        return {
          ...item,
          value: [
            ...item.value,
            {
              value: null,
              title: "",
              icon: localImageUrl,
              imageFile: event.target.files[0],
            }
          ]
        }
      };

      return item;
    }))
  };

  const onSave = async (e) => {
    setIsUpdating(true);
      try {
        e.preventDefault();
        const toUpdate = new FormData();
        planData.map(item => {
          if (item.key === "ad_ids") {
            const value = item.value.map(img => ({ id: img?.value ?? null, external_url: img?.title ?? "", image_url: !img?.imageFile ? img?.icon ?? "" : "" }));
            const hasNewImages = item?.value?.some(img => !!img?.imageFile);
            toUpdate.append("ads_data", JSON.stringify(value));
            if (hasNewImages) {
              for (let { imageFile } of item.value.filter(img => !!img?.imageFile)) {
                toUpdate.append("ads_images", imageFile);
              }
            };
          } else {
            if (item.value != null) toUpdate.append(item.key, item.value);
          }
        });

        const res = await updatePlan(businessId, toUpdate);

        if (res?.sucess) {
          SuccessMessage({ message: t("save-feature") });
          setPlanMeta(planData);
        } else {
          ErrorMessage({ message: t("erro-save") });
        }
    } catch (err) {
      ErrorMessage({ message: err.message });
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    getPlanData();
  }, []);

  const renderPlanList = () => {
    return planData?.length ? planData.map(plan => {
      switch (plan?.type) {
        case "bool":
          return (
            <ToggleConfig
              title={plan.name} 
              description={plan.description}
              name={plan.key}
              value={plan.value}
              onChange={onChange}
              canEdit={Boolean(plan.canEdit)}
              isEnabled={!plan.parent_key || Boolean(planData?.find?.(item => item.key === plan.parent_key)?.value)}
            />
          );

        case "int":
          return (
            <InputDinamic
              title={plan.name}
              name={plan.key}
              id={plan.key}
              description={plan.description}
              type="number"
              value={plan.value || ""}
              onChange={onChange}
              canEdit={Boolean(plan.canEdit)}
              isEnabled={!plan.parent_key || Boolean(planData?.find?.(item => item.key === plan.parent_key)?.value)}
            /> 
          );

        case "string":
          if (plan?.options?.list?.length && !plan?.options?.multiple) {
            return (
              <SelectConfig 
                title={plan.name}
                description={plan.description}
                options={plan.options.list}
                onChange={onChange}
                id={plan.key}
                name={plan.key}
                value={plan.value}
                canEdit={Boolean(plan.canEdit)}
                isEnabled={!plan.parent_key || Boolean(planData?.find?.(item => item.key === plan.parent_key)?.value)}
              />
            )
          }

          if (plan?.options?.multiple) {
            return (
              <SelectItem 
                title={plan.name}
                description={plan.description}
                items={plan.options.list}
                onChange={onChange}
                name={plan.key}
                id={plan.key}
                value={plan.value}
                isEnabled={!plan.parent_key || Boolean(planData?.find?.(item => item.key === plan.parent_key)?.value)}
                parentConfig={planData?.find?.(item => item.key === plan.parent_key)}
              />
            )
          }

          if (plan?.options?.custom) {
            return (
              <AdsComponent
                title={plan.name}
                value={plan.value}
                onDragEnd={onDragEnd}
                onChangeTitle={onChangeAdsTitle}
                onDeleteAd={onDeleteAd}
                onUploadImage={onUploadImage}
                isEnabled={!plan.parent_key || Boolean(planData?.find?.(item => item.key === plan.parent_key)?.value)}
              />
            )
          }

          return (
            <InputDinamic
              title={plan.name}
              name={plan.key}
              id={plan.key}
              type="text"
              description={plan.description}
              value={plan.value || ""}
              onChange={onChange}
              canEdit={Boolean(plan.canEdit)}
              isEnabled={
                !plan.parent_key || 
                Boolean(planData?.find?.(item => item.key === plan.parent_key)?.value) || 
                planData?.find?.(item => item.key === plan.parent_key)?.type !== "bool"
              }
            /> 
          )
          
        default:
          return <></>
      }
    }) : [];
  };

  return {
    renderPlanList,
    canSave: JSON.stringify(planData) !== JSON.stringify(planMeta),
    onSave,
    isUpdating,
    isPlanLoading
  }
}
