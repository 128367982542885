import React, { useState } from 'react';
import * as Styled from './styles';
import { Colors } from '../../../../../values/colors';

const Icon = (props) => {
  const { item } = props;
  const [hasError, setHasError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  if (hasError) return;

  return (
    <Styled.Icon color={item.color || Colors.defaultBlue} show={isLoaded && !hasError}>
      <img 
        src={item.icon} 
        style={{ width: 20, height: 20 }}
        onError={() => setHasError(true)}
        onLoad={() => setIsLoaded(true)}
        alt={item.name}
      />
    </Styled.Icon>
  )
};

const SelectItem = (props) => {
  return (
    <Styled.Container isEnabled={props?.isEnabled}>
      <Styled.ConfigTitle>{props.title}</Styled.ConfigTitle>
      <Styled.Description>{props.description}</Styled.Description>

      <Styled.Line>
        {props?.items?.map((item, index) => {
          return (
            <Styled.Item
              onClick={(e) => props.onChange(e, item)}
              key={`${item.name}-${index}`} 
              checked={
                Boolean(props?.parentConfig?.value) && 
                (props?.value?.split(",").includes(String(item.value)) || 
                !props?.value)
              }
              id={props.name}
            >
              {!!item.icon && <Icon item={item} />}

              <Styled.ItemTitle 
                checked={
                  Boolean(props?.parentConfig?.value) && 
                  (props?.value?.split(",").includes(String(item.value)) || 
                  !props?.value)
                }
                id={props.name}
              >
                {item.title}
              </Styled.ItemTitle>
              <Styled.ItemCheckbox
                name={props.name} 
                id={props.id} 
                type='checkbox'
                checked={
                  Boolean(props?.parentConfig?.value) && 
                  (props?.value?.split(",").includes(String(item.value)) || 
                  !props?.value)
                }
              />
            </Styled.Item>
          )
        })}
      </Styled.Line>

      <hr style={{ height: 1, color: Colors.ultraLightGray2 }} />
    </Styled.Container>
  )
};

export default SelectItem;
