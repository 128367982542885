import API from '../api'


export const getBusiness = async () => {
  try {
    const res = await API.get('/companies')

    return res.data
  } catch (error) {
    return error
  }
}

export const getPlans = async () => {
  try {
    const res = await API.get('/plansAll');
    return res.data;
  
  } catch (error) {
    return error
  }
}

export const getOneCompanies = async (companies) => {
  try {
    const res = await API.get(`/businesses/${companies}`);
    return res.data;
  } catch (error) {
    return error
  }
}

export const getActiveCompanies = async () => {
  try {
    const res = await API.get('/businesses/list-all');
    return res.data;
  } catch (err) {
    return err;
  }
}

export const companiesGetPlanMeta = async (id) => {
  try {
    const res = await API.get(`/companiesGetPlanMeta/${id}`)

    return res.data
  } catch (error) {
    return error
  }
}

export const getAllCompanies = async (page, name, perPage) => {
  try {
    const res = await API.get('/businesses', {
      params: {
        page,
        name,
        perPage
      }
   });

    return res.data
  } catch (error) {
    return error
  }
}


export const uploadImagem = async (file) => {
  try {
    
    const formData = new FormData();
    formData.append('file', file);

    const response = await API.post('/uploadimagem', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    
    if (response.status !== 200) {
      throw new Error('Response was not ok');
    }

    return await response;
  } catch (error) {
    return error;
  }
}

export const createOrUpdatePlanMeta = async (data) => {
  try {
    return await API.post('/companiesPlanMeta', data)

  } catch (error) {
    return error
  }
}

export const createCompanies = async (data) => {
  try {
    return await API.post('/businesses', data);

  } catch (error) {
    return error
  }
}

export const updateCompaniesActive = async (companyId, activeData) => {
  try {
    const { data } = await API.put(`/businesses/${companyId}/active`, activeData);

    return data.data
  } catch (error) {
    return error
  }
}

export const updateCompanies = async (companyId, companies) => {
  try {
    return await API.put(`/businesses/${companyId}`, companies);
  } catch (error) {
    return error
  }
}

export const getPlansMetaList = async () => {
  try {
    const res = await API.get('/plansmetalist');

    const teleEnabledObject = res.data.find(item => item.key === "tele_enabled");
    res.data = res.data.filter(item => item.key !== "tele_enabled");
    res.data.push(teleEnabledObject);
    return res.data;
  
  } catch (error) {
    return error
  }
}

export const getWidgets = async (business_id) => {
  try {
    return await API.get(`/businesses/${business_id}/widgets/`);
  } catch (error) {
    return error;
  }
}

export const updateWidgets = async (business_id, newWidgetList) => {
  try {
    const res = await API.post(`businesses/${business_id}/widgets`, { widgets: newWidgetList });

    return res.data;
  } catch (error) {
    return error;
  }
}

export const getPlansMeta = async (business_id) => {
  try {
    const res = await API.get(`/businesses/${business_id}/plans-meta`);

    return res.data;
  } catch (error) {
    return error;
  }
}

export const updatePlan = async (business_id, data) => {
  try {
    const res = await API.put(`/businesses/${business_id}/plan`, data);
    return res.data;
  } catch (error) {
    return error;
  }
};
