import React from "react";
import * as Styled from "./styles";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TrashIcon from "../../../../../assets/images/trash.svg";
import OrdiationIcon from "../../../../../assets/images/ordination.svg";

export const SortableAd = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    flexDirection: "row"
  };

  return (
    <Styled.ImageItem ref={setNodeRef} style={style} {...attributes}>
      <Styled.Image
        {...listeners} 
        src={props.icon}
      />
      <div 
        style={{ 
          position: "absolute", 
          height: 69, 
          width: 113, 
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: 8, marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Styled.AdIndex style={{ zIndex: 11 }}>
            <span>{props.index}</span>
          </Styled.AdIndex>
          <Styled.DeleteButton style={{ zIndex: 11 }} onClick={() => props.onDeleteAd(props.id)}>
            <img src={TrashIcon} style={{ width: 10, height: 10 }} />
          </Styled.DeleteButton>
        </div>

        <div style={{ marginBottom: 8, marginLeft: 8, zIndex: 11, backgroundColor: "#fff", height: 16, width: 15, display: "flex", justifyContent: "center", alignItems: "center",  borderRadius: 5  }}>
          <img src={OrdiationIcon} style={{ zIndex: 20, width: 10, height: 10 }} />
        </div>
      </div>
      <Styled.ImageInfoContainer>
        <Styled.Description>Deseja inserir um link nesta imagem? Digite o endereço completo abaixo:</Styled.Description>
        <div style={{ width: 423 }}>
          <input
            className="border rounded-lg px-3 py-2 w-full focus:outline-none focus:border-blue-500"
            value={props.title}
            type="text"
            onChange={(e) => props.onChangeAdsTitle(props.id, e.target.value)}
          />
        </div>
      </Styled.ImageInfoContainer>
    </Styled.ImageItem>
  )
};

