import { Icon, Input } from "./styles"


export const InputSearch  = ({ onChange, placeholder, icon, defaultValue = '' }) => {

    return (
        <>
        <Input 
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        />
        {
        icon ? (
            <Icon>{icon}</Icon>
        ) : ''}
        </>
    );

}