import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-activity";
import { Check, ChevronLeft, Edit2, Plus, Trash, X } from "react-feather";
import { useTranslation } from "react-i18next";
import General from "../../../services/General";
import List from "../../../services/List";
import Managers from "../../../services/Managers";
import { ErrorMessage, SuccessMessage } from "../../../utils/alerts";
import { Colors } from "../../../values/colors";
import { Badge, FlatButton, Grid, Text, TextButton, Title, View } from "../../BaseComponents/styles";
import { Button } from "../../Button";
import { FormField } from "../../FormField";
import { Line } from "../../Line/styles";
import ProfilePhoto from "../../ProfilePhoto";
import { InputInfoText } from "../components/InputInfoText";
import { AddAdminUser } from "./AddAdminUser";
import Employees from "../../../services/Employees";

export const AdministrativeUsers = ({ onClose, show }) => {

  const [ t, i18n ] = useTranslation('common');
  
  const [ roles, setRoles ] = useState([]);
  const [ managers, setManagers ] = useState([]);
  const [ addAdminVisible, setAddAdminVisible ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ sectorList, setSectorList ] = useState([]);
  const [ businessUnitList, setBusinessUnitList ] = useState([]);


  const fetchRoles = useCallback(async () => {
    const {roles} = await General.roles();
    setRoles(roles);
  }, []);

  const handleOnClose = () => {
    if(addAdminVisible){
      setAddAdminVisible(false);
    }else{
      onClose();
    }
  }

  const getData = async () => {
    setLoading(true);
    const data = await Managers.list();
    setManagers([ ...data.managers, ...data.invites.map(e => ({ ...e, pending: true })) ]);
    setLoading(false);
  };

  const loadLists = async () => {
    const { sectors: _sectors } = await List.sectors();
    const { business_units: _businessUnits } = await List.businessUnits();

    setSectorList(_sectors.map(s => s.title).filter(s => s.length != 0));
    setBusinessUnitList(_businessUnits.map(bu => bu.title).filter(bu => bu.length != 0));
  }

  useEffect(()=>{
    loadLists();
    fetchRoles();
  }, []);

  useEffect(() => {
    getData();
  }, [ show ]);

  const UserItem = ({ user }) => {
    const [ name, setName ] = useState(user.name);
    const [ sector, setSector ] = useState(user.sector);
    const [ role, setRole ] = useState(user.role);
    const [ businessUnit, setBusinessUnit ] = useState(user.businessUnit);

    const [ oldValues, setOldValues ] = useState({ 
      sector: user.sector, 
      businessUnit: user.business_unit,
      name: user.name,
      role: user.role
    });
    const [ editing, setEditing ] = useState(false);

    const saveChanges = async () => {
      setEditing(false);
      setOldValues({ sector, businessUnit, name, role });
      await Managers.update({ id: user.id, businessUnit, role, sector, name })
      SuccessMessage({ message: t('save_successfully') });
    }

    const edit = () => {
      setOldValues({ sector, businessUnit, role, name });
      setEditing(true);
    }

    const cancel = () => {
      setName(oldValues.name);
      setSector(oldValues.sector);
      setBusinessUnit(oldValues.businessUnit);
      setRole(oldValues.role);
      setEditing(false);
    }

    const remove = async () => {
      await Managers.delete(user.id);
    }

    const resendInvite = (userId) => {
      try {
        const response = Employees.resendInvites({ userId });
        SuccessMessage({ message: 'Convite reenviado' });
      } catch (e) {
        ErrorMessage({ message: 'Erro ao reenviar convites' });
      }
    }

    return <View style={{marginTop: '1.5rem'}}>
        <Line align={'space-between'}>

        <InputInfoText 
            className="title-name" 
            disabled={!editing}  
            value={name} 
            onValueChange={setName}
            />

          <Line align={'end'}>
            {user?.pending && <Badge style={{marginRight: '.75rem'}}>Convidado</Badge>}
            {!user?.pending && (!editing ?
            <>
              <FlatButton onClick={edit}>
                <Edit2 color={Colors.mediumGray} />
              </FlatButton>
              <FlatButton onClick={remove}>
                <Trash color={Colors.red} style={{marginLeft: '.75rem'}}/>
              </FlatButton>
            </>
            :
            <>
              <FlatButton onClick={() => saveChanges() }>
                <Check color={Colors.mediumGray} />
              </FlatButton>
              <FlatButton onClick={cancel}>
                <X color={Colors.red} style={{marginLeft: '.75rem'}} />
              </FlatButton>
            </>
            )}
          </Line>
        </Line>
        <Line>
          <Text style={{fontWeight: 700, fontSize: 14}}>{user.email}</Text>
        </Line>
        {editing && <Line gap={10} style={{marginTop: 5}}>
          {roles.map(r => <FlatButton type="button" key={r.id} onClick={()=>setRole(r.id)}>
            <Badge color={role === r.id ? 'primaryPurple' : 'lightGray'}>{r.name}</Badge>
          </FlatButton>)}
        </Line>}
        <InputInfoText options={sectorList} disabled={!editing} placeholder={'Usuário sem setor'} value={sector} onValueChange={setSector}/>
        <Line>
          <InputInfoText options={businessUnitList} className="filled" disabled={!editing} placeholder={'Usuário sem unidade'} value={businessUnit} onValueChange={setBusinessUnit}/>
          {user?.pending && (
            <Button color={Colors.secondaryPurple} style={{ height: 28, width: 200, padding: 0 }} onClick={() => resendInvite(user?.id)}>
              <p style={{ margin: 0, fontSize: 12 }}>Reenviar convite</p>
            </Button>
          )}
        </Line>
    </View>
  }

  return (
    <View>
      <Line>
        <ChevronLeft color={Colors.lightGray} onClick={handleOnClose}/>
        <Title style={{marginLeft: '1rem'}}>{t('administrative_users')}</Title>
      </Line>

      <View style={{marginTop: '2rem', position: 'relative'}}>
        {!addAdminVisible && <View>
          {loading ? 
            <center><Spinner color={Colors.primaryPurple} style={{marginTop: 100}} size={24}/></center>
          : <>
            {managers.map(user=><UserItem key={user.id} user={user}/>)}
            <Line align={'center'} style={{marginTop: '2rem'}}>
              <Button onClick={()=> setAddAdminVisible(true)} color={Colors.primaryPurple}>{t('add_new')} <Plus size={16}/></Button>
            </Line>
          </>}
          </View>
        }
        
        <AddAdminUser roles={roles} onConfirm={()=>getData()} visible={addAdminVisible} onClose={()=>setAddAdminVisible(false)}/>
      </View>
    </View>
  );
}