import React, { useEffect, useState, useContext, useCallback } from "react";
import { Plus, Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "../../components/Box";
import DataTable from "../../components/DataTable";
import TextBox from "../../components/TextBox";
import { Colors } from "../../values/colors";
import { Button } from '../../components/Button';
import debounce from 'lodash.debounce';

import {
  getAllCompanies,
  updateCompaniesActive
} from "../../services/CompaniesManagement";

import { Text } from "../../components/BaseComponents/styles";
import Switch from "../../components/ToggleSwitch";
import { InputSearch } from "../../components/InputSearch";
import { AddCompaniesModal } from "./Modals/AddCompaniesModal";
import { CompaniesManagementContext } from "./context/CompaniesManagement";
import { EditCompaniesModal } from "./Modals/EditCompaniesModal";
import { Spinner } from "react-activity";

export const CompaniesManagement = () => {
  const [companiesData, setCompaniesData] = useState([]);
  const [modalCompaniesVisible, setModalCompaniesVisible] = useState(false);
  const [modalEditCompaniesVisible, setModalEditCompaniesVisible] = useState(false);
  const [dataProps, setDataProps] = useState({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [ count, setCount ] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [businessSearch, setBusinessSearch] = useSearchParams();
  
  const navigate = useNavigate();
  const { setDataValue } = useContext(CompaniesManagementContext)

  const [setOptions] = useOutletContext();

  const { t } = useTranslation("common");

  useEffect(() => {
    setOptions({
      start: (
        <Text
          color={"white"}
          weight={700}
          style={{ textDecoration: "underline" }}
        >
          {t("businesses")}
        </Text>
      ),
      end: [
        <InputSearch 
          placeholder={t('search-companies')} onChange={e => {
            setSearch(e.target.value);
            setBusinessSearch({name: e.target.value});
          }} 
          defaultValue={businessSearch.get('name')}
          icon={<Search size={18} />} 
        />,
        <Button color={Colors.defaultBlue} onClick={() => setModalCompaniesVisible(true)} icon={<Plus size={18} />}>{t('add-companies')}</Button>
      ]
    });
    return () => setOptions(null);
  }, []);

  const debouncedFetch = useCallback(
    debounce(async (page, search, perPage) => {
      setIsLoading(true);
      const { businesses, counter } = await getAllCompanies(page, search, perPage);
      setCompaniesData(businesses);
      setCount(counter);
      setIsLoading(false);
    }, 300), 
    []
  );

  useEffect(() => {
    debouncedFetch(page, businessSearch.get('name') ?? search, perPage);
  }, [page, search, perPage, debouncedFetch, businessSearch]);

  const handleChange = (event, data) => {
    const dataToSend = {
      active: data.active === 1 ? 0 : 1
    }
    setCompaniesData(state => {
      return state.map(item => {
        if(item.id === data.id) {
          return {
            ...item,
            active: data.active === 1 ? 0 : 1
          }
        }
        return item
      })
    })

    updateCompaniesActive(data.id, dataToSend);
  };

  const handleOpenModalEditCompanies = async (event ,row) => {
    event.preventDefault();
    setModalEditCompaniesVisible(true)
    setDataProps(row)
  }

  const  handleOpenModalPlans = async (event ,row) => {
    event.preventDefault();
    setDataValue(row);
    navigate(`/businesses/${row.id}/plan`);
  };

  const refreshCompanies = () => {
    setPage(prev => {
      if (prev === 1) {
        debouncedFetch(page, search, perPage);
      }
      return 1;
    });
  }

  return (
    <>
      <Box noPadding={true}>
        {isLoading ? (
          <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: 40, alignItems: "center" }}>
            <Spinner color={Colors.primaryBlue} size={24} />
          </div>
        ) : (
          <DataTable
            collumns={[
              {
                title: "Id",
                key: "id",
                field: TextBox,
              },
              {
                title: "Nome fantasia",
                key: "title",
                field: TextBox,
              },
              {
                title: "Razão social",
                key: "name",
                field: TextBox,
              },
              {
                title: "Empresa pai",
                key: "parent_title",
                field: TextBox,
              },
              {
                title: `Plano`,
                key: "plan_name",
                field: TextBox,
              },
              {
                title: "Status",
                key: "active",
                render: (row) => {
                  return (
                    <Switch
                      id={row.id}
                      key={row.id+1}
                      name={row.id}
                      toggled={row.active === 1}
                      onChange={(e) => handleChange(e, row)}
                    />
                    )
                },
              },
            ]}
            actions={(row) => [
              <div className="flex items-center justify-end gap-x-6">
                <button
                    type="button"
                    style={{ color: '#6240FF' , borderColor: '#6240FF'}}
                    className=" text-blue-700 font-semibold shadow-sm text-sm hover:text-blue-500 py-1 px-8 border border-blue-500 hover:border-blue-400 rounded-3xl"
                    onClick={(e) => handleOpenModalEditCompanies(e, row)}
                >
                    {t('edit-companies')}
                </button>
                <button
                  type="button"
                  style={{ color: '#3ED1D2' , borderColor: '#3ED1D2'}}
                  className="text-green-400 font-semibold shadow-sm text-sm hover:text-green-400 py-1 px-8 border border-green-500 hover:border-green-400 rounded-3xl"
                  onClick={(e) => handleOpenModalPlans(e, row)}
              >
                  {t('edit-plans')}
              </button>
              </div>
            ]}
            data={companiesData}
            defaultActions={false}
            isLeaderBoard={true}
            totalEntries={count}
            defaultPage={page}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
          />
        )}
      </Box>
      <AddCompaniesModal data={dataProps} onAddCompanies={() => refreshCompanies()} open={modalCompaniesVisible} onClose={() => setModalCompaniesVisible(false)} />
      <EditCompaniesModal data={dataProps} onEditCompanies={() => refreshCompanies()} open={modalEditCompaniesVisible} onClose={() => setModalEditCompaniesVisible(false)} />

    </>
  );
};
