import styled from "styled-components";
import { Colors } from '../../../../../values/colors';

export const Container = styled.div`
  padding-left: 25px;
  padding-top: 22px;
  background-color: ${(props) => props.isEnabled ? Colors.white : "#E7EBEF"};
  pointer-events: ${(props) => !props.isEnabled && "none"};
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const ConfigTitle = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  color: ${Colors.blueGray2};
  font-size: 14px;
  font-weight: 600;
  line-height: 10.98px;
  width: 290px;
`;

export const Description = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  color: ${Colors.blueMist};
  font-size: 13px;
  font-weight: 400;
  line-height: 20.48px;
  margin-top: 27px;
  margin-bottom: 22px;
`;

export const Item = styled.button`
  padding: 5px 10px;
  min-height: 45px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.checked ? '#6240FF' : '#F6F6F6'};
  margin-right: 8px;
  margin-bottom: 21px;
  cursor: pointer;
`;

export const Icon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: ${(props) => props.show ? "flex" : "none"};
  align-items: center;
  justify-content: center;
  background-color: ${( props ) => props.color};
`;

export const ItemTitle = styled.label`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.1px;
  margin: 0px 8px;
  cursor: pointer;
  color: ${(props) => props.checked ? '#FFFFFF' : '#3A3A3A'};
`;

export const ItemCheckbox = styled.input.attrs({ type: 'checkbox' })`
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 0.5em;
  border: 1px solid #7D7D7D;
  outline: none;
  cursor: pointer;

  :checked {
    background-color: #fff;
    border: none;
  }

  :checked::after {
    content: '✓';
    color: #6240FF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -2px;
    left: 0px;
  }
`;