export const Colors = {
  primaryColor: '#3F4059',
  primaryBlue: '#3de3e4',
  primaryBlue2: '#3EDADB',
  primaryPurple: '#5D3DED',
  secondaryPurple: '#6240FF',
  black40: 'rgba(0,0,0,.4)',
  black60: 'rgba(0,0,0,.6)',
  blueGray: '#475464',
  blueGray2: '#485465',
  blueMist: "#8593A5",
  lightGray: '#A8BAC8',
  mutedGray: '#8896A9',
  ultraLightGray: '#D5D8DF',
  ultraLightGray2: '#EFF2F7',
  successGreen: '#4fd5ae',
  orange: '#ffa000',
  mediumGray: '#657e94',
  red: '#FA3333',
  redSecondary: '#FC615F',
  cadetBlue: '#A9BBC9',
  primaryGreen: '#36B37E',
  secondaryGreen: '#2DCE9F',
  lightBg: '#F5F6FA',
  defaultBlue: '#3ED1D2',
  defaultOrange: '#FF8850',
  defaultRed: '#FA3333',
  white: "#FFFFFF",
  black: "#000000",
};