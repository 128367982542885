import styled from "styled-components";
import { Colors } from '../../../../../values/colors';

export const Container = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 22px;
  background-color: ${(props) => props.isEnabled ? Colors.white : "#E7EBEF"};
  pointer-events: ${(props) => !props.isEnabled && "none"};
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ConfigTitle = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  color: ${Colors.blueGray2};
  font-size: 14px;
  font-weight: 600;
  line-height: 10.98px;
  width: 493px;
`;

export const Description = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  color: ${Colors.blueMist};
  font-size: 13px;
  font-weight: 400;
  line-height: 20.48px;
`;

export const ImagesContainer = styled.div`
  margin-top: 22px;
  margin-bottom: 22px;
`;

export const ImageItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 22px;
`;

export const Image = styled.img`
  width: 113px; 
  height: 69px; 
  border-radius: 12px; 
  position: relative;
  z-index: 10;
`;

export const ImageInfoContainer = styled.div`
  margin-left: 22px;
  width: 442px;
  text-align: justify;
`;

export const AdIndex = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-family: 'Inter', sans-serif;
    color: #000000;
    font-size: 10px;
    line-height: 9.9px;
    font-weight: 400;
  }
`;

export const DeleteButton = styled.button`
  width: 18px;
  height: 18px;
  background-color: #FF8484;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadImageContainer = styled.label`
  width: 113px; 
  height: 69px; 
  border-radius: 12px; 
  position: relative;
  border: 1px dashed ${Colors.defaultBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  span {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 9px;
    font-weight: 500;
    color: ${Colors.defaultBlue};
    line-height: 15.86px;
    margin-top: 2px;
    cursor: pointer;
  }
`;
