import styled from "styled-components";
import { Colors } from '../../../../../values/colors';

export const Container = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 22px;
  background-color: ${(props) => props.isEnabled ? Colors.white : "#E7EBEF"};
  pointer-events: ${(props) => !props.isEnabled && "none"};
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ConfigTitle = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  color: ${Colors.blueGray2};
  font-size: 14px;
  font-weight: 600;
  line-height: 10.98px;
  width: 493px;
  `;

export const Description = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  color: ${Colors.blueMist};
  font-size: 13px;
  font-weight: 400;
  line-height: 20.48px;
  margin-top: 27px;
  margin-right: 25px;
  margin-bottom: 22px;
`;
