import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Main from './screens/Main';
import General from './screens/General';
import UserProfiler from './screens/UserProfiler';
import Usage from './screens/Usage';
import Indicators from './screens/Indicators';
import TeleStats from './screens/TeleStats';
import Report from './screens/Report';
import Users from './screens/Users';
import Login from './screens/Auth/Login';
import HomePage from './screens/HomePage';
import NewDash from './screens/NewDash';
import { useState } from 'react';
import Reset from './screens/Auth/Reset';
import Association from './screens/Auth/Association';
import Invite from './screens/Auth/Invite';
import BusinessSwitch from './screens/BusinessSwitch';
import ManuGpt from './modules/ManuGpt';
import Business from './modules/Business';
import { PreRegistrationLayoutManagement } from './screens/PreRegistrationLayoutManagement'
import { PreRegistration } from './screens/PreRegistration'
import { LeaderBoard } from './screens/LeaderBoard'
import { CompaniesManagement } from './screens/CompaniesManagement'
import { PlansManagement } from './screens/CompaniesManagement/Planos'
import VitalscanAlerts from './screens/VitalscanAlerts';

const Child = ({ counter }) => {
  return (
    <p>{counter}</p>
  )
}

const Parent = () => {
  const [counter, setCounter] = useState(0);

  const increaseCounter = () => {
    setCounter(currentValue => currentValue + 1)
  }

  return (
    <>
      <button onClick={increaseCounter}>Aumentar</button>
      <Child counter={counter} />
    </>
  )
}

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Business />}>
          <Route path='/pre-registration/:currentBusiness' element={<PreRegistrationLayoutManagement />} />
          <Route path='/test' element={<Parent />} />
          <Route path='/switch_business' element={<BusinessSwitch />} />
          <Route path='/login' element={<Login />} />
          <Route path='/redefine-senha' element={<Reset />} />
          <Route path='/associacao' element={<Association />} />
          <Route path='/convite' element={<Invite />} />

          <Route path='/' element={<Main />}>
            <Route path='' element={<General />} />
            <Route path='user_profiler' element={<UserProfiler />} />
            <Route path='usage' element={<Usage />} />
            <Route path='indicators' element={<Indicators />} />
            <Route path='tele_stats' element={<TeleStats />} />
            <Route path='report' element={<Report />} />
            <Route path='users' element={<Users />} />
            <Route path='dashboards' element={<NewDash key="single" />} />
            <Route path='vitalscan-alerts' element={<VitalscanAlerts />} />
            <Route path='general-dashboard' element={<NewDash key="multi" multi />} />
            <Route path='pre-registration' element={<PreRegistration />} />
            <Route path='leaderboard' element={<LeaderBoard />} />
            <Route path='businesses' element={<CompaniesManagement />} />
            <Route path='businesses/:businessId/plan' element={<PlansManagement />} />
          </Route>
        </Route>
        <Route path='/manu'>
          <Route path='' element={<ManuGpt />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
