import { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import '../../../Shered/styles/global.css';
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal";
import { FileInput, FileInputButton, Icon, ImageLogo } from "./styles";
import Select from 'react-select';

import { getOneCompanies, uploadImagem, updateCompanies, getActiveCompanies } from "../../../services/CompaniesManagement";
import { ErrorMessage, SuccessMessage } from "../../../utils/alerts";
import { Spinner } from "react-activity";
import { Colors } from "../../../values/colors";

const statesData = [
    'RO', 'AC', 'AM', 'RR', 'PA', 'AP', 'TO', 'MA', 'PI', 'CE',
    'RN', 'PB', 'PE', 'AL', 'SE', 'BA', 'MG', 'ES', 'RJ', 'SP',
    'PR', 'SC', 'RS', 'MS', 'MT', 'GO', 'DF'
];

const initialInputDataStates = {
    name: '',
    title: '',
    cnpj: '',
    field: '',
    cep: '',
    city: '',
    state: '',
    neighborhood: '',
    street: '',
    parent_id: '',
    logo: '',
    conditions: ''
}

export const EditCompaniesModal = ({ open, onClose, onEditCompanies, data }) => {

    const { t } = useTranslation('common');
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputData, setInputData] = useState(initialInputDataStates);
    const [inputUrlImage, setInputUrlImage] = useState('');
    const [companies, setCompanies] = useState([]);
    const [companiesData, setCompaniesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = async (id) => {
        const resp = await getOneCompanies(id);
        const companiesResp = await getActiveCompanies();
        const companyList = companiesResp.map(company => ({ value: company.id, label: `${company.id} - ${company.title}` }));
        setInputData(resp);
        setInputUrlImage(resp.logo);
        setCompanies(companyList);
        setCompaniesData(companiesResp);
    };

    useEffect(() => {
        if(open) loadData(data.id);
    }, [open]);
    
    const handleCloseModal = () => {
        setSelectedFile(null)
        setInputData(initialInputDataStates)
        onClose()
    };

    const handleChange = (event, key) => {
        if (key === "parent_id") {
          const newConditions = companiesData.find(company => company.id === event.value)?.conditions;
          setInputData({ ...inputData, parent_id: event.value, conditions: newConditions });
          return;
        };
        
        if(key !== undefined) setInputData({ ...inputData, [key]: event.target.value });
        
        if(event.target.id === 'file') {
            if(event.target.files[0].size > 0) {
              validarArquivo(event.target.files[0])
            }
        }
    };

    function handleImageClick() {
        const fileInput = document.getElementById('file');
        fileInput.value = null; // Reset do valor do input
    }

    function validarArquivo(file) {
        var reader = new FileReader();
      
        reader.onload = function(event) {
          var img = new Image();
          img.src = event.target.result;
      
          img.onload = function() {
            if (
              file.type === "image/png" &&
                (img.width >= 200 || img.width <= 300) &&
                (img.height >= 200 || img.height <= 300)
            ) {
              // Imagem válida
              setSelectedFile(file)
              setInputUrlImage(URL.createObjectURL(file))
            } else {
              setSelectedFile(null)
              inputData.logo = null
              ErrorMessage({ message: t("invalid-image") });
            }
          };
        };
        reader.readAsDataURL(file);
      }

  async function handleSubmit(event) {
    try {
      setIsLoading(true);
      event.preventDefault();

      if (!inputData.title) {
        ErrorMessage({ message: t("erro-title") });
        return;
      }
      if (!inputData.conditions) {
        ErrorMessage({ message: t("erro-conditions") });
        return;
      }

      const dataToSend = new FormData();
      Object.keys(inputData).map(key => dataToSend.append(key, inputData[key]));

      if(data.id) {
        dataToSend.append('business_id', data.id);
        if (selectedFile) {
          dataToSend.append('logo', selectedFile);
        }
        const res = await updateCompanies(data.id, dataToSend);
        if(res?.status === 200 && !res?.data?.error) {
            onEditCompanies && onEditCompanies();
            SuccessMessage({ message: t("msg-update-companies") });
            handleCloseModal();
          } else {
            ErrorMessage({ message: t("erro-update-companies") });
          }
        }
    } catch (error) {
      console.warn(error)
      ErrorMessage({ message: t("erro-update-companies") });
    } finally {
      setIsLoading(false);
    }    
  }


    return (
        <>
            <Modal containerStyle={{ width: '100%', marginTop: 20, marginBottom: 20 }} open={open} onClose={onClose} disableOnClose>
                <form
                    onSubmit={handleSubmit}
                    className='flex flex-col gap-57 w-full max-w-xs-33'
                >
                  <p style={{ cursor: 'pointer', textAlign: 'end' }} onClick={onClose}>X</p>
                    <div className="border-b border-gray-900/10 pb-12">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-blue-800">Editar empresa</h2>

                                <div className="relative mt-6 flex items-center gap-x-4">
                                    <ImageLogo src={inputUrlImage} />
                                    <div className="text-sm leading-6">
                                        <p className="font-semibold text-gray-900">
                                            <span className="absolute inset-0" />
                                            Logo da empresa
                                        </p>
                                        <p className="text-gray-600">Enviar o logo em .png com largura mínima de 200px e máxima de 300px (com altura proporcional), sem margens ao redor e com fundo transparente. </p>
                                        <label for="file" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">

                                            <FileInputButton>
                                                <div className="m-2 flex items-center justify-start">
                                                    <Icon />
                                                    Enviar imagem
                                                    <FileInput
                                                      id="file"
                                                      name="file"
                                                      onClick={handleImageClick}
                                                      onChange={(e) => handleChange(e)}
                                                      class="sr-only"
                                                    />
                                                </div>
                                            </FileInputButton>
                                            
                                        </label>
                                    </div>
                                </div>
                                <div className="mt-10 grid grid-cols-1 gap-x-16 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                        <label htmlFor="parend_id" className="flex block text-sm font-medium leading-6 text-gray-900">
                                            Empresa pai
                                        </label>
                                        <div className="mt-2">
                                          <Select 
                                            options={companies} 
                                            name="parent_id"
                                            id="parend_id"
                                            placeholder="Selecione a empresa"
                                            value={companies[companies.findIndex(company => company.value === inputData.parent_id)]}
                                            onChange={(e) => handleChange(e, 'parent_id')}
                                            className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10 grid grid-cols-1 gap-x-16 gap-y-8 sm:grid-cols-6">

                                    <div className="sm:col-span-3">
                                        <label htmlFor="title" className="flex block text-sm font-medium leading-6 text-gray-900">
                                            Nome fantasia <p class="text-red-500 text-sm italic">*</p>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={inputData.title}
                                                onChange={(e) => handleChange(e, 'title')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="fantasy" className="block text-sm font-medium leading-6 text-gray-900">
                                            Razão social
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="fantasy"
                                                id="fantasy"
                                                value={inputData.name}
                                                onChange={(e) => handleChange(e, 'name')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="cnpj" className="block text-sm font-medium leading-6 text-gray-900">
                                            CNPJ
                                        </label>
                                        <div className="mt-2">
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                type="text"
                                                name="cnpj"
                                                id="cnpj"
                                                value={inputData.cnpj}
                                                onChange={(e) => handleChange(e, 'cnpj')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="sector" className="block text-sm font-medium leading-6 text-gray-900">
                                            Setor
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="sector"
                                                id="sector"
                                                value={inputData.field}
                                                onChange={(e) => handleChange(e, 'field')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                            <div>
                            <h2 className="text-base font-semibold mt-10 leading-3 text-blue-800">Plano</h2>
                            <div className="mt-6 grid grid-cols-1 gap-x-16 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                        <label htmlFor="conditions" className="flex text-sm font-medium leading-6 text-gray-900">
                                            Condição do plano <p class="text-red-500 text-sm italic">*</p>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="conditions"
                                                id="conditions"
                                                value={inputData.conditions}
                                                onChange={(e) => handleChange(e, 'conditions')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                </div>
                            </div>
                            </div>
                        
                            <div>
                                <h2 className="text-base font-semibold mt-10 leading-3 text-blue-800">Endereço</h2>
                                <div className="mt-6 grid grid-cols-1 gap-x-16 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-2 sm:col-start-1">
                                        <label htmlFor="cep" className="block text-sm font-medium leading-6 text-gray-900">
                                            CEP
                                        </label>
                                        <div className="mt-2">
                                            <InputMask
                                                mask="99999-999"
                                                type="text"
                                                name="cep"
                                                id="cep"
                                                value={inputData.cep}
                                                onChange={(e) => handleChange(e, 'cep')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                            Cidade
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={inputData.city}
                                                onChange={(e) => handleChange(e, 'city')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                                            Estado
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="state"
                                                name="state"
                                                value={inputData.state}
                                                onChange={(e) => handleChange(e, 'state')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            >
                                                {statesData.map(option =>
                                                    <option key={option} value={option}>{option}</option>
                                                )}

                                            </select>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="neighborhood" className="block text-sm font-medium leading-6 text-gray-900">
                                            Bairro
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="neighborhood"
                                                id="neighborhood"
                                                value={inputData.neighborhood}
                                                onChange={(e) => handleChange(e, 'neighborhood')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="street" className="block text-sm font-medium leading-6 text-gray-900">
                                            Rua
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="street"
                                                id="street"
                                                value={inputData.street}
                                                onChange={(e) => handleChange(e, 'street')}
                                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className="mt-2 flex items-center justify-between gap-x-6">
                        <button type="button" onClick={handleCloseModal} className="text-sm font-semibold leading-6 text-gray-900">
                            Cancelar
                        </button>
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                          type="button"
                          onClick={(e) => handleSubmit(e)}
                          className="rounded-3xl bg-indigo-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          disabled={isLoading}
                          style={{ background: isLoading ? Colors.lightGray : Colors.secondaryPurple }}
                        >
                          {isLoading ? (
                            <Spinner color="#fff" size={12} />
                            ) : "Salvar dados"
                          }
                        </button>
                        </div>
                    </div>
                </form>

            </Modal>

        </>
    );

}