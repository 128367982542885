import React from 'react';
import * as Styled from './styles';
import { Colors } from '../../../../../values/colors';

const InputDinamic = ({ isEnabled, canEdit, ...rest }) => {
  return (
    <Styled.Container isEnabled={isEnabled}>
      <Styled.Line>
        <Styled.ConfigTitle>{rest.title}</Styled.ConfigTitle>

        <div style={{ width: 258 }}>
          <input
            className="border rounded-lg px-3 py-2 w-full focus:outline-none focus:border-blue-500"
            {...rest}
            disabled={!canEdit}
          />
        </div>
      </Styled.Line>

      <Styled.Description>{rest.description}</Styled.Description>
      <hr style={{ height: 1, color: Colors.ultraLightGray2 }} />
    </Styled.Container>
  );
};

export default InputDinamic;
