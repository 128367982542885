import React from 'react';
import * as Styled from "./styles";
import { Colors } from '../../../../../values/colors';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableAd } from './SortableAd';
import UploadIcon from "../../../../../assets/images/upload.svg";

const Ads = (props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <Styled.Container isEnabled={props?.isEnabled}>
      <Styled.ConfigTitle>{props.title}</Styled.ConfigTitle>

      <Styled.ImagesContainer>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={props.onDragEnd}
        >
          <SortableContext
            items={props.value}
            strategy={verticalListSortingStrategy}
          >
            <Styled.ImageItem>
              <Styled.UploadImageContainer htmlFor='fileInput'>
                <input style={{ display: "none" }} accept='image/*' id="fileInput" type='file' onChange={props.onUploadImage} />
                <img src={UploadIcon} style={{ width: 17, height: 17 }} alt="" />
                <span>Enviar uma imagem</span>
              </Styled.UploadImageContainer>
              <Styled.ImageInfoContainer>
                <Styled.Description>Adicione suas imagens ao carrossel. Elas devem ser em PNG, ter 570 pixels de largura por 350 pixels de altura e pesar no máximo 1MB. O texto na imagem deve ter entre 12 e 20 pixels.</Styled.Description>
              </Styled.ImageInfoContainer>
            </Styled.ImageItem>

            {props.value?.map((ad, index) => (
              <SortableAd
                key={ad.value}
                id={index}
                title={ad.title} 
                icon={ad.icon} 
                onChangeAdsTitle={props.onChangeTitle}
                index={index + 1}
                onDeleteAd={props.onDeleteAd}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Styled.ImagesContainer>

      <hr style={{ height: 1, color: Colors.ultraLightGray2 }} />
    </Styled.Container>
  )
};

export default Ads;
