import React from 'react';
import Switch from '../../../../../components/ToggleSwitch';
import * as Styled from './styles';
import { Colors } from '../../../../../values/colors';

const Toggle = (props) => {
  const { title, value, onChange, isEnabled, description, name, canEdit } = props;

  return (
    <Styled.Container isEnabled={isEnabled} key={`${name}-${title}`}>
      <Styled.Line>
        <Styled.ConfigTitle>{title}</Styled.ConfigTitle>

        <Switch
          id={name}
          key={name}
          name={name}
          toggled={value}
          onChange={onChange}
          disabled={!canEdit}
        />
      </Styled.Line>

      <Styled.Description>{description}</Styled.Description>

      <hr style={{ height: 1, color: Colors.ultraLightGray2 }} />
    </Styled.Container>
  )
}

export default Toggle;
