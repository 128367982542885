import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as Styled from './styles';
import OrdinationIcon from "../../../../../assets/images/ordination.svg";

const SortableItem = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    flexDirection: "row"
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img src={OrdinationIcon} style={{ marginBottom: 17, marginRight: 16 }} width={9} alt="" />
      <Styled.Container>
        <Styled.Text>{props.label}</Styled.Text>
      </Styled.Container>
    </div>
  );
}

export default SortableItem;
