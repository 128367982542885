import styled from 'styled-components'
import { Colors } from '../../../../../values/colors';

export const Container = styled.div`
  width: 315px;
  height: 47.41px;
  border-radius: 4.87px;
  border: 1px solid ${Colors.blueMist};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  font-family: "Nunito Sans";
  font-size: 13px;
  line-height: 20.48px;
  color: ${Colors.black};
  margin-left: 11px;
`;
