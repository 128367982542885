import React, { useEffect, useState } from "react";
import '../../../Shered/styles/global.css';
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import { Box } from "../../../components/Box";
import { Colors } from "../../../values/colors";
import { ActionButton } from '../../../components/ActionButton';

import {
  getWidgets,
  updateWidgets,
} from "../../../services/CompaniesManagement";

import {
  ButtonContainer,
  Description,
  TitleHeader
} from "./styles";

import { ErrorMessage, SuccessMessage } from "../../../utils/alerts";
import { Text } from "../../../components/BaseComponents/styles";
import { SortableItem } from "./components";
import { Button } from "../../../components/Button";
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { usePlan } from "./hooks/usePlan";
import { Spinner } from "react-activity";

export const PlansManagement = () => {
  const [setOptions] = useOutletContext();
  const { t } = useTranslation("common");
  const valuesData = JSON.parse(localStorage.getItem('companiesData'));
  const [loading, setLoading] = useState();
  const [selectedPage, setSelectedPage] = useState("plan_general_config");
  const [widgets, setWidgets] = useState([]);
  const [responseWidgets, setResponseWidgets] = useState([]);
  const { renderPlanList, canSave, onSave, isUpdating, isPlanLoading } = usePlan();
  const { businessId } = useParams();

  useEffect(() => {
    setOptions({
      start: [
        <Text
          color={"white"}
          weight={700}
          style={{ textDecoration: "underline" }}
        >
          {valuesData.name} {'> Plano'}
        </Text>
      ]
    });
    return () => setOptions(null);
  }, []);

  const getAllPlanMeta = async () => {
    try {
      const widgetsList = await getWidgets(businessId);
      setResponseWidgets(widgetsList.data);
      setWidgets(widgetsList.data);
    } catch (error) {
      console.warn(error.message);
    }
  };

  useEffect(() => {
    getAllPlanMeta();
  }, []);
  
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setWidgets((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      })
    }
  };

  const handleSaveNewWidgetsList = async () => {
    setLoading(true);
    try {
      const updatedWidgets = widgets?.map((widget, index) => ({
        ...widget,
        widget_position: index + 1
      }));

      const response = await updateWidgets(businessId, updatedWidgets);

      if (!response.sucess) {
        setLoading(false);
        ErrorMessage({ message: t("error_update_ordination") });
        return;
      }

      setResponseWidgets(widgets);
      setLoading(false);
      SuccessMessage({
        message: t("save_successfully"),
      });
    } catch {
      setLoading(false);
      ErrorMessage({ message: t("error_update_ordination") })
    }
  };

  return (
    <>
      <Box noPadding={true} noHeader>
        <ButtonContainer justify="flex-start">
          <Button 
            onClick={() => setSelectedPage("plan_general_config")}
            style={{ 
              borderRadius: 5, 
              borderWidth: 1, 
              backgroundColor: selectedPage === "plan_general_config" ? Colors.secondaryPurple : Colors.white,
              borderColor: selectedPage === "plan_general_config" ? undefined : Colors.secondaryPurple, 
              borderStyle: 'solid' 
            }} 
            textColor={selectedPage === "plan_general_config" ? Colors.white : Colors.secondaryPurple}
          >
            {t('plan_general_config')}
          </Button>
          <Button 
            onClick={() => setSelectedPage("widgets_ordening")}
            color={selectedPage === "widgets_ordening" ? Colors.secondaryPurple : Colors.white} 
            style={{ 
              borderRadius: 5, 
              marginLeft: 23, 
              borderColor: selectedPage === "widgets_ordening" ? undefined : Colors.secondaryPurple,
              borderStyle: "solid",
              borderWidth: 1,
            }} 
            textColor={selectedPage === "widgets_ordening" ? Colors.white : Colors.secondaryPurple}
          >
            {t('widgets_ordening')}
          </Button>
        </ButtonContainer>

        <hr style={{ height: 1, color: Colors.ultraLightGray2 }} />

        {selectedPage === "plan_general_config" && (
          <>
            {isPlanLoading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 40 }}>
                <Spinner color={Colors.primaryBlue} size={24} />
              </div>
            ) : (
              <div>
                {renderPlanList()}
                <ButtonContainer justify="flex-end">
                  <ActionButton 
                    color={Colors.defaultBlue} 
                    isLoading={isUpdating && canSave} 
                    onClick={(e) => onSave(e)}
                    showIcon={false}
                    disabled={isUpdating || !canSave}
                    style={{ width: 167 }}
                  >
                    {t('save-plans')}
                  </ActionButton>
                </ButtonContainer>
              </div>
            )}
          </>
        )}

        {selectedPage === "widgets_ordening" && (
          <div style={{ marginLeft: 25, marginRight: 26, marginTop: 22 }}>
            <TitleHeader>{t("initial_page_content")}</TitleHeader>
            <Description>{t("initial_page_content_description")}</Description>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              {widgets?.length && (
                <SortableContext
                  items={widgets}
                  strategy={verticalListSortingStrategy}
                >
                  {widgets?.map((widget) => <SortableItem key={widget.id} id={widget.id} label={widget.widget_name} />)}
                </SortableContext>
              )}
            </DndContext>

            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: 64 }}>
              <ActionButton
                onClick={handleSaveNewWidgetsList}
                color={Colors.defaultBlue}
                showIcon={false}
                style={{ width: 167, height: 42 }}
                disabled={widgets === responseWidgets || loading}
                isLoading={loading}
              >
                <p>{t('save-plans')}</p>
              </ActionButton>
            </div>
          </div>
        )}
      </Box>
    </>
  );
};
