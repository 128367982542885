import styled from "styled-components";
import { Colors } from "../../values/colors";


export const Title = styled.h2`
  color: ${Colors.blueGray};
  font-weight: 700;
	font-size: ${props => props.size || 20}px;
`;

export const TextValue = styled.h1`
  color: ${(props) => props.color || Colors.primaryBlue};
  font-weight: 700;
	font-size: 70px;
`;

export const Text = styled.span`
	color: ${props => props.color || Colors.blueGray};
	font-weight: ${props => props.weight || 400};
	font-size: ${props => props.size + 'px' || 'inherit'};
	white-space: pre-line;
`;

export const Paragraph = styled.p`
	color: ${props => props.color || Colors.blueGray};
	font-weight: ${props => props.weight || 400};
	font-size: ${props => props.size + 'px' || 'inherit'};
	white-space: pre-line;
`;

export const Quote = styled.div`
	color: ${props => props.color || 'black'};
	font-weight: ${props => props.weight || 400};
	font-size: ${props => props.size + 'px' || 'inherit'};
	white-space: pre-line;
	background-color: #EEE;
	padding: 10px;
	border-radius: 7px;
`;

export const View = styled.div`
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};

	&.fade.hide {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
	}
	&.fade.show {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
		transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
	}

	${props => props.centered && `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	`}
`;

export const TextButton = styled.span`
	color: ${props => props.color || Colors.blueGray2};
	font-size: 1rem;
	cursor: pointer !important;
	display: flex;
	user-select: none;
`;

export const Badge = styled.span`
	padding: 5px 10px;
	color: ${props => Colors[props.color] || Colors.successGreen};
	border-radius: 20px;
	font-size: 12px;
	font-weight: 600;
  border: 1px solid ${props => Colors[props.color] || Colors.successGreen};
`;

export const Input = styled.input`
	font-size: 1rem;
	font-weight: 600;
	color: ${props => props.color || Colors.blueGray2};
`;

export const FieldInput = styled.input`
	font-size: 1rem;
	font-weight: 600;
	color: ${props => props.color || Colors.blueGray2};
`;

export const FlatButton = styled.button`
	background: transparent;
	color: ${props => props.textColor};
	border: none;
	outline: inherit;
	font-size: 14px;
	cursor: pointer !important;
	display: flex;
	justify-content: center;
	align-items: center;

	&:disabled {
		opacity: .6;
	}

	${props=> props.opacity ? `
		padding: 5px;
		${!props.disabled && `
			border-radius: 100%;
			transition: background-color .2s ease-in-out;
			&:hover{
				background-color: #eee;
			}
			&:active{
				background-color: #ccc;
			}
		`}
	` : ''}
`;

export const TextArea = styled.textarea`
	border-radius: 7px;
	padding: 1rem;
	border: 1px solid ${Colors.ultraLightGray};
	outline: none;
	width: 100%;
	height: ${props => props.height ? props.height + 'px' : 'auto'};
	resize: none;
	font-weight: 700;
	font-size: 1rem;

`;

export const CircleBox = styled.div`
	${props => `
	background-color: ${props.color || Colors.cadetBlue};
	width: ${props.size || 48}px;
	height: ${props.size || 48}px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	`};
`;

export const TransparencyOver = styled.div`
	&.hide{
		opacity: 0;
		visibility: hidden;
	}
	position: absolute;
	width: 100%;
	height: 100%;
	background: black;
	opacity: .5;
	visibility: visible;
	transition: opacity .2s, visibility .2s;

`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.active ? '#4FD5AE' : '#ccc')};
  margin: 0 1rem;
`;

export const ToggleWrapper = styled.div`
  position: relative;
  width: 56px;
  height: 28px;
`;

export const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer !important;
  top: 4px;
  left: 0;
  right: 10px;
  bottom: 0;
  background-color: ${(props) => (props.checked ? '#E7EBEF' : '#ccc')};
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: '';
    height: 25px;
    width: 25px;
    left: ${(props) => (props.checked ? '1px' : '-5px')};
    bottom: 0px;
    background-color: #4FD5AE;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${(props) => (props.checked ? 'translateX(26px)' : 'translateX(0)')};
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const HeadingsContainer = styled.div`
  padding: 5px 0px 1px 32px;
`;

export const TocList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const HeadingItem = styled.li`
`;

export const HeadingLink = styled.a`
  font-size: 14px;
  font-weight: bold;
  color: #003049;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #836AFB;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:not(.last-item)::after {
    content: "";
    position: absolute;
    height: calc(92px);
    width: 1px;
    background: #836AFB;
    left: 15px;
    top: -3rem;
    transform: translateY(-50%);
  }
`;